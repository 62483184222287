var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "drawer-layout",
    {
      attrs: {
        "destroy-on-close": "",
        "is-preview": false,
        isMaskClosable: _vm.isMaskClosable,
        "hide-edit-btn": _vm.hideEditBtn,
        title: _vm.setTitle,
        show: _vm.open,
        isForm: _vm.isForm,
        permission: "system:rule:list:button:edit",
        "confirm-btn-disable": _vm.confirmBtnDisable,
      },
      on: {
        show: _vm.show,
        closed: _vm.closed,
        save: _vm.save,
        edit: _vm.edit,
        onConfirm: _vm.onConfirm,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "allow-scroll",
          class: { "allow-scroll": _vm.isPreview },
          attrs: { slot: "" },
          on: { scroll: _vm.onScroll },
          slot: "default",
        },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "名称" } },
            [_vm._v(_vm._s(_vm.curRow.name))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "序号" } },
            [_vm._v(_vm._s(_vm.curRow.sequenceNo))]
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "规则类型" } },
            [_vm._v(_vm._s(_vm.getRuleType(_vm.curRow.ruleType)))]
          ),
          _vm.isTaskSelect(_vm.curRow.ruleType)
            ? _c(
                "info-item",
                { attrs: { "label-width": _vm.labelWidth, label: "适用对象" } },
                _vm._l(_vm.curRow.stages, function (one) {
                  return _c("a-tag", { key: one.stageId }, [
                    _vm._v("\n        " + _vm._s(one.stageName) + "\n      "),
                  ])
                }),
                1
              )
            : _vm._e(),
          _vm.curRow.ruleType == "LEVEL"
            ? _c(
                "info-item",
                { attrs: { "label-width": _vm.labelWidth, label: "适用对象" } },
                _vm._l(_vm.curRow.members, function (one) {
                  return _c("a-tag", { key: one.positionId }, [
                    _vm._v(
                      "\n        " + _vm._s(one.positionName) + "\n      "
                    ),
                  ])
                }),
                1
              )
            : _vm._e(),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "适用部门" } },
            _vm._l(_vm.curRow.departments, function (one) {
              return _c("a-tag", { key: one.departId }, [
                _vm._v("\n        " + _vm._s(one.departName) + "\n      "),
              ])
            }),
            1
          ),
          _c(
            "info-item",
            { attrs: { "label-width": _vm.labelWidth, label: "适用职位" } },
            _vm._l(_vm.curRow.positions, function (one) {
              return _c("a-tag", { key: one.positionId }, [
                _vm._v("\n        " + _vm._s(one.positionName) + "\n      "),
              ])
            }),
            1
          ),
          _vm.curRow.ruleType == "LEVEL"
            ? [
                _c("g-title", [_vm._v("等级规则")]),
                _c("edit-level-form", {
                  attrs: { isEdit: false, formList: _vm.curRow.levelRules },
                }),
              ]
            : _vm._e(),
          _vm.curRow.ruleType == "KPI"
            ? [
                _c("g-title", [_vm._v("考核规则")]),
                _c("edit-assess-form", {
                  attrs: { isEdit: false, formData: _vm.curRow },
                }),
              ]
            : _vm._e(),
          _vm.curRow.ruleType == "MONEY"
            ? [
                _c("g-title", [_vm._v("价格规则")]),
                _c("edit-money-form", {
                  attrs: { isEdit: false, formData: _vm.curRow },
                }),
              ]
            : _vm._e(),
          _vm.curRow.ruleType == "FACTOR"
            ? [
                _c("g-title", [_vm._v("单拎系数规则")]),
                _c("edit-factor-form", {
                  attrs: { isEdit: false, formData: _vm.curRow },
                }),
              ]
            : _vm._e(),
          _vm.curRow.ruleType == "DIFFICULTLY"
            ? [
                _c("g-title", [_vm._v("难度系数规则")]),
                _c("edit-coefficient-form", {
                  attrs: { isEdit: false, formData: _vm.curRow },
                }),
              ]
            : _vm._e(),
          _vm.curRow.ruleType == "KPI_SCORE"
            ? [
                _c("g-title", [_vm._v("考核得分系数规则")]),
                _c("edit-kpi-form", {
                  attrs: { isEdit: false, formData: _vm.curRow },
                }),
              ]
            : _vm._e(),
          _vm.curRow.ruleType == "NOT_LOCAL"
            ? [
                _c("g-title", [_vm._v("难易程度系数")]),
                _c("edit-kpi-form", {
                  attrs: { isEdit: false, formData: _vm.curRow },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "allow-scroll", attrs: { slot: "form" }, slot: "form" },
        [
          _c("g-title", [_vm._v("基本信息")]),
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入名称" },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "序号", prop: "sequenceNo" } },
                [
                  _c("a-input-number", {
                    staticClass: "w-full",
                    attrs: { placeholder: "请输入序号" },
                    model: {
                      value: _vm.formData.sequenceNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "sequenceNo", $$v)
                      },
                      expression: "formData.sequenceNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "规则类型", prop: "ruleType" } },
                [
                  _c(
                    "a-select",
                    {
                      on: { change: _vm.ruleTypeChange },
                      model: {
                        value: _vm.formData.ruleType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "ruleType", $$v)
                        },
                        expression: "formData.ruleType",
                      },
                    },
                    _vm._l(Object.values(_vm.ruleTypeList), function (item) {
                      return _c("a-select-option", { key: item.value }, [
                        _vm._v(
                          "\n            " + _vm._s(item.label) + "\n          "
                        ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "适用对象", prop: "targetType" } },
                [
                  _vm.isTaskSelect(_vm.formData.ruleType)
                    ? _c("a-tree-select", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "tree-checkable": "",
                          "tree-data": _vm.getTreeStageList,
                          "search-placeholder": "选择适用阶段",
                          placeholder: "请选择适用阶段",
                        },
                        on: { change: _vm.changeStageList },
                        model: {
                          value: _vm.selectedStages,
                          callback: function ($$v) {
                            _vm.selectedStages = $$v
                          },
                          expression: "selectedStages",
                        },
                      })
                    : _vm.formData.ruleType == "LEVEL"
                    ? _c("a-tree-select", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "tree-checkable": "",
                          "tree-data": _vm.getTreePositionList,
                          "search-placeholder": "选择职位",
                          placeholder: "请选择适用职位",
                        },
                        on: { change: _vm.changeMemberList },
                        model: {
                          value: _vm.selectedMembers,
                          callback: function ($$v) {
                            _vm.selectedMembers = $$v
                          },
                          expression: "selectedMembers",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择部门", prop: "selectedDepart" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      treeDefaultExpandAll: "",
                      "tree-checkable": "",
                      "tree-data": _vm.departList,
                      "search-placeholder": "选择部门",
                      placeholder: "请选择适用部门",
                    },
                    on: { change: _vm.changeDepartList },
                    model: {
                      value: _vm.selectedDeparts,
                      callback: function ($$v) {
                        _vm.selectedDeparts = $$v
                      },
                      expression: "selectedDeparts",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "选择职位", prop: "selectedPositions" } },
                [
                  _c("a-tree-select", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "tree-checkable": "",
                      "tree-data": _vm.getTreePositionList,
                      "search-placeholder": "选择职位",
                      placeholder: "请选择适用职位",
                    },
                    on: { change: _vm.changePositionList },
                    model: {
                      value: _vm.selectedPositions,
                      callback: function ($$v) {
                        _vm.selectedPositions = $$v
                      },
                      expression: "selectedPositions",
                    },
                  }),
                ],
                1
              ),
              _vm.formData.ruleType == "LEVEL"
                ? [
                    _c("g-title", [_vm._v("等级规则")]),
                    _c("edit-level-form", {
                      attrs: {
                        isEdit: true,
                        formList: _vm.formData.levelRules,
                      },
                    }),
                  ]
                : _vm._e(),
              _vm.formData.ruleType == "KPI"
                ? [
                    _c("g-title", [_vm._v("考核规则")]),
                    _c("edit-assess-form", {
                      attrs: { isEdit: true, formData: _vm.formData },
                    }),
                  ]
                : _vm._e(),
              _vm.formData.ruleType == "MONEY"
                ? [
                    _c("g-title", [_vm._v("价格规则")]),
                    _c("edit-money-form", {
                      attrs: { isEdit: true, formData: _vm.formData },
                    }),
                  ]
                : _vm._e(),
              _vm.formData.ruleType == "FACTOR"
                ? [
                    _c("g-title", [_vm._v("单拎系数规则")]),
                    _c("edit-factor-form", {
                      attrs: { isEdit: true, formData: _vm.formData },
                      on: { setDif: _vm.setDif },
                    }),
                  ]
                : _vm._e(),
              _vm.formData.ruleType == "DIFFICULTLY"
                ? [
                    _c("g-title", [_vm._v("难度系数规则")]),
                    _c("edit-coefficient-form", {
                      attrs: { isEdit: true, formData: _vm.formData },
                      on: { setDif: _vm.setDif },
                    }),
                  ]
                : _vm._e(),
              _vm.formData.ruleType == "KPI_SCORE"
                ? [
                    _c("g-title", [_vm._v("考核得分系数规则")]),
                    _c("edit-kpi-form", {
                      attrs: { isEdit: true, formData: _vm.formData },
                      on: { setDif: _vm.setDif },
                    }),
                  ]
                : _vm._e(),
              _vm.formData.ruleType == "NOT_LOCAL"
                ? [
                    _c("g-title", [_vm._v("难易程度系数")]),
                    _c("edit-kpi-form", {
                      attrs: { isEdit: true, formData: _vm.formData },
                      on: { setDif: _vm.setDif },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }