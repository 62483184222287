<template>
  <drawer-layout
    destroy-on-close
    :is-preview="false"
    :isMaskClosable="isMaskClosable"
    :hide-edit-btn="hideEditBtn"
    :title="setTitle"
    :show="open"
    :isForm="isForm"
    permission="system:rule:list:button:edit"
    :confirm-btn-disable="confirmBtnDisable"
    @show="show"
    @closed="closed"
    @save="save"
    @edit="edit"
    @onConfirm="onConfirm"
  >
    <div class="allow-scroll" slot="" @scroll="onScroll" :class="{ 'allow-scroll': isPreview }">
      <g-title>基本信息</g-title>
      <!-- 注意这里是预览的地方，取值对象为curRow -->
      <info-item :label-width="labelWidth" label="名称">{{ curRow.name }}</info-item>
      <info-item :label-width="labelWidth" label="序号">{{ curRow.sequenceNo }}</info-item>
      <info-item :label-width="labelWidth" label="规则类型">{{ getRuleType(curRow.ruleType) }}</info-item>
      <info-item :label-width="labelWidth" label="适用对象" v-if="isTaskSelect(curRow.ruleType)">
        <a-tag v-for="one in curRow.stages" :key="one.stageId">
          {{ one.stageName }}
        </a-tag>
      </info-item>
      <info-item :label-width="labelWidth" label="适用对象" v-if="curRow.ruleType == 'LEVEL'">
        <a-tag v-for="one in curRow.members" :key="one.positionId">
          {{ one.positionName }}
        </a-tag>
      </info-item>

      <info-item :label-width="labelWidth" label="适用部门">
        <a-tag v-for="one in curRow.departments" :key="one.departId">
          {{ one.departName }}
        </a-tag>
      </info-item>
      <info-item :label-width="labelWidth" label="适用职位">
        <a-tag v-for="one in curRow.positions" :key="one.positionId">
          {{ one.positionName }}
        </a-tag>
      </info-item>
      <template v-if="curRow.ruleType == 'LEVEL'">
        <g-title>等级规则</g-title>
        <edit-level-form :isEdit="false" :formList="curRow.levelRules" />
      </template>
      <template v-if="curRow.ruleType == 'KPI'">
        <g-title>考核规则</g-title>
        <edit-assess-form :isEdit="false" :formData="curRow" />
      </template>
      <template v-if="curRow.ruleType == 'MONEY'">
        <g-title>价格规则</g-title>
        <edit-money-form :isEdit="false" :formData="curRow" />
      </template>
      <!-- <translate-check :propsFormData="formData" :editMode="false" v-if="curRow.ruleType == 'KPI'"></translate-check> -->
      <!-- <translate-coefficient :propsFormData="formData" :editMode="false" v-if="curRow.ruleType == 'FACTOR'">
      </translate-coefficient> -->
      <template v-if="curRow.ruleType == 'FACTOR'">
        <g-title>单拎系数规则</g-title>
        <edit-factor-form :isEdit="false" :formData="curRow" />
      </template>
      <!-- <translate-difficulty v-if="curRow.ruleType == 'DIFFICULTLY'"></translate-difficulty> -->
      <template v-if="curRow.ruleType == 'DIFFICULTLY'">
        <g-title>难度系数规则</g-title>
        <edit-coefficient-form :isEdit="false" :formData="curRow" />
      </template>
      <template v-if="curRow.ruleType == 'KPI_SCORE'">
        <g-title>考核得分系数规则</g-title>
        <edit-kpi-form :isEdit="false" :formData="curRow" />
      </template>
      <template v-if="curRow.ruleType == 'NOT_LOCAL'">
        <g-title>难易程度系数</g-title>
        <edit-kpi-form :isEdit="false" :formData="curRow" />
      </template>
      <!--       <template v-if="curRow.ruleType == 'COEFFICIENT'">
        <g-title>系数规则</g-title>
        <edit-cof-form :isEdit="false" :formData="curRow" />
      </template> -->
    </div>
    <div class="allow-scroll"  slot="form">
      <!-- 注意这里是编辑的地方，取值对象为formData -->
      <g-title>基本信息</g-title>
      <a-form-model ref="dataForm" layout="horizontal" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item label="名称" prop="name">
          <a-input v-model="formData.name" placeholder="请输入名称"></a-input>
        </a-form-model-item>
        <a-form-model-item label="序号" prop="sequenceNo">
          <a-input-number v-model="formData.sequenceNo" placeholder="请输入序号" class="w-full" />
        </a-form-model-item>
        <a-form-model-item label="规则类型" prop="ruleType">
          <a-select v-model="formData.ruleType" @change="ruleTypeChange">
            <a-select-option v-for="item in Object.values(ruleTypeList)" :key="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="适用对象" prop="targetType">
          <!-- <a-select v-model="formData.targetType">
            <a-select-option :value="1">翻译</a-select-option>
            <a-select-option :value="2">制作</a-select-option>
          </a-select> -->
          <!-- 如果是考核规则或者单拎系数，适用对象为任务 -->
          <a-tree-select
            v-if="isTaskSelect(formData.ruleType)"
            v-model="selectedStages"
            style="width: 100%"
            tree-checkable
            :tree-data="getTreeStageList"
            search-placeholder="选择适用阶段"
            placeholder="请选择适用阶段"
            @change="changeStageList"
          />
          <a-tree-select
            v-else-if="formData.ruleType == 'LEVEL'"
            v-model="selectedMembers"
            style="width: 100%"
            tree-checkable
            :tree-data="getTreePositionList"
            search-placeholder="选择职位"
            placeholder="请选择适用职位"
            @change="changeMemberList"
          />
        </a-form-model-item>
        <a-form-model-item label="选择部门" prop="selectedDepart">
          <a-tree-select
            treeDefaultExpandAll
            v-model="selectedDeparts"
            style="width: 100%"
            tree-checkable
            :tree-data="departList"
            search-placeholder="选择部门"
            placeholder="请选择适用部门"
            @change="changeDepartList"
          />
        </a-form-model-item>
        <a-form-model-item label="选择职位" prop="selectedPositions">
          <a-tree-select
            v-model="selectedPositions"
            style="width: 100%"
            tree-checkable
            :tree-data="getTreePositionList"
            search-placeholder="选择职位"
            placeholder="请选择适用职位"
            @change="changePositionList"
          />
        </a-form-model-item>
        <template v-if="formData.ruleType == 'LEVEL'">
          <g-title>等级规则</g-title>
          <edit-level-form :isEdit="true" :formList="formData.levelRules" />
        </template>
        <template v-if="formData.ruleType == 'KPI'">
          <g-title>考核规则</g-title>
          <edit-assess-form :isEdit="true" :formData="formData" />
        </template>
        <template v-if="formData.ruleType == 'MONEY'">
          <g-title>价格规则</g-title>
          <edit-money-form :isEdit="true" :formData="formData" />
        </template>
        <!-- <translate-check :propsFormData="formData" :editMode="true" v-if="formData.ruleType == 'KPI'"></translate-check> -->
        <!-- <translate-coefficient :propsFormData="formData" :editMode="true" v-if="formData.ruleType == 'FACTOR'">
        </translate-coefficient> -->
        <template v-if="formData.ruleType == 'FACTOR'">
          <g-title>单拎系数规则</g-title>
          <edit-factor-form @setDif="setDif" :isEdit="true" :formData="formData" />
        </template>
        <!-- <translate-difficulty is-edit v-if="formData.ruleType == 'DIFFICULTLY'"></translate-difficulty> -->
        <template v-if="formData.ruleType == 'DIFFICULTLY'">
          <g-title>难度系数规则</g-title>
          <edit-coefficient-form @setDif="setDif" :isEdit="true" :formData="formData" />
        </template>
        <template v-if="formData.ruleType == 'KPI_SCORE'">
          <g-title>考核得分系数规则</g-title>
          <edit-kpi-form @setDif="setDif" :isEdit="true" :formData="formData" />
        </template>
        <template v-if="formData.ruleType == 'NOT_LOCAL'">
          <g-title>难易程度系数</g-title>
          <edit-kpi-form @setDif="setDif" :isEdit="true" :formData="formData" />
        </template>
        <!--         <template v-if="formData.ruleType == 'COEFFICIENT'">
          <g-title>系数规则</g-title>
          <edit-cof-form @setDif="setDif" @setDifs="setDifs" :isEdit="true" :formData="formData" />
        </template> -->
      </a-form-model>
    </div>
  </drawer-layout>
</template>

<script>
import { DrawerMixins } from '@/mixins/DrawerMixins'
import TranslateLevel from './translate-level.vue'
import TranslateCheck from './translate-check.vue'
import TranslateCoefficient from './translate-coefficient.vue'
import TranslateDifficulty from './translate-difficulty'
import EditLevelForm from '@/components/edit/form-list/edit-level-form.vue'
import EditAssessForm from '@/components/edit/edit-assessRule-form.vue'
import EditMoneyForm from '@/components/edit/edit-money-form.vue'
import EditCoefficientForm from '@/components/edit/edit-coefficient-form.vue'
import EditCofForm from '@/components/edit/edit-cof-form.vue'
import EditFactorForm from '@/components/edit/edit-factor-form.vue'
import EditKpiForm from '@/components/edit/edit-kpi-form.vue'
import { ruleDefaultConfig } from './default-config'
import { createRuleTypeList } from '@/enums/ruleTypeList'

export default {
  components: {
    TranslateLevel,
    TranslateCheck,
    TranslateCoefficient,
    TranslateDifficulty,
    EditLevelForm,
    EditAssessForm,
    EditMoneyForm,
    EditFactorForm,
    EditKpiForm,
    EditCoefficientForm,
    EditCofForm
  },
  mixins: [DrawerMixins],
  props: {
    propsFilters: {
      type: Object,
      default: () => ({})
    },
    positionId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      confirmBtnDisable: true,
      defaultUser: [],
      defaultAssUser: [],
      selectedDeparts: [],
      selectedPositions: [],
      selectedMembers: [],
      selectedStages: [],
      stageTypes: [
        { id: 1, name: '否' },
        { id: 2, name: '是' }
      ],
      rules: {},
      url: {
        edit: '/rule/edit',
        add: '/rule/add'
      },
      formData: {},
      labelWidth: '140',
      layout: {
        labelCol: {
          style: 'width: 140px',
          span: 6
        },
        wrapperCol: {
          span: 18
        }
      },
      ruleTypeList: {}
    }
  },
  methods: {
    setDif(data) {
      this.formData = Object.assign({}, this.formData, { difficulties: data })
    },
    setDifs(data) {
      this.formData = Object.assign({}, this.formData, { factors: data })
    },
    isTaskSelect(ruleType) {
      return ['KPI', 'FACTOR', 'MONEY', 'DIFFICULTLY', 'KPI_SCORE', 'COEFFICIENT', 'NOT_LOCAL'].includes(ruleType)
    },
    getRuleType(type) {
      return this.ruleTypeList[type] ? this.ruleTypeList[type].label : ''
    },
    onScroll(e) {
      // Y轴滚动距离 偏移高度 总高度
      let { scrollTop, clientHeight, scrollHeight } = e.target
      // console.log('onScroll...', { e, scrollTop, clientHeight, scrollHeight })
      console.log(`scrollTop + clientHeight ${scrollTop + clientHeight} >= scrollHeight-1 ${scrollHeight - 1}`)
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        console.log('滚到底部啦！！！')
        this.confirmBtnDisable = false
      }
    },

    initForm() {
      this.formData = Object.assign(this.propsFilters, this.getDefaultForm())
      this.selectedDeparts = []
      this.selectedPositions = []
      this.selectedStages = []
    },

    show() {
      console.log('显示...')
      this.selectedMembers = []
      this.confirmBtnDisable = true
      this.getPosition()
      this.getDepartList()
      this.getStageList()
      this.curRow.difficulties?.forEach(item => {
        if (item.title == '急稿系数') {
          item.isFast = true
        }
      })
      if (this.isEdit) {
        this.formData = this.curRow
        const { departments } = this.formData
        const { positions, members } = this.formData
        this.selectedDeparts = departments.map(item => item.departId)
        console.log({ positions: positions })
        this.selectedPositions = positions.map(item => {
          item.positionId
        })
        this.selectedMembers = members.map(item => {
          item.positionId
        })
        this.selectedStages = stages.map(item => item.stageId)
        console.log({ 'show this.selectedPositions': this.selectedPositions })
      } else if (this.isForm) {
        this.formData = Object.assign({}, this.getDefaultForm())

        if (this.formData.typeId == 0) {
          this.oldFormData = JSON.parse(JSON.stringify(this.formData))
        }
      } else {
        this.formData = this.curRow
      }
      this.formData = { ...this.formData, ...this.propsFilters }
      console.log({ 'this.formData': this.formData })
      setTimeout(() => this.$nextTick(this.calcConfirmBtnDisable), 500)
      // this.calcConfirmBtnDisable()
    },

    calcConfirmBtnDisable() {
      let allowScroll = document.querySelector('.allow-scroll')
      // console.log({ allowScroll })
      if (!allowScroll) {
        return
      }
      console.log(
        `allowScroll.scrollHeight ${allowScroll.scrollHeight} <= allowScroll.clientHeight ${allowScroll.clientHeight}`
      )
      if (allowScroll.scrollHeight <= allowScroll.clientHeight) {
        console.log('高度低于窗口可视区域高度')
        this.confirmBtnDisable = false
      }
    },
    onConfirm() {
      console.log('rule-modal onConfirm')
      this.$emit('onConfirm', this.curRow.id)
    },

    startEdit() {
      if (this.isEdit) {
        this.formData = this.curRow
        const { departments, positions, members, stages } = this.formData
        this.selectedDeparts = departments.map(item => item.departId)
        console.log({ 'this.formData': this.formData })
        if (positions) {
          this.selectedPositions = positions.map(item => item.positionId)
          console.log({ 'show this.selectedPositions': this.selectedPositions })
        }
        if (members) {
          this.selectedMembers = members.map(item => item.positionId)
          console.log({ 'show this.selectedMembers': this.selectedMembers })
        }
        if (stages) {
          this.selectedStages = stages.map(item => item.stageId)
          console.log({ 'show this.selectedStages': this.selectedStages })
        }
      }
    },

    setFormData() {},
    ruleTypeChange(e) {
      if (e == 'DIFFICULTLY' || e == 'FACTOR' || e == 'KPI_SCORE' || e == 'NOT_LOCAL' || e == 'COEFFICIENT') {
        if (!this.formData.difficulties) {
          this.formData.difficulties = []
        }
        if (!this.formData.factors) {
          this.formData.factors = []
        }
      }
      if (e == 1) {
        // 选中等级规则且没有对应字段，就手动添加
        if (!this.formData.levelRules) {
          this.formData.levelRules = []
        }
        if (!this.curRow.levelRules) {
          this.curRow.levelRules = []
        }
        console.log('手动设置levelRules', this.formData.levelRules, this.curRow.levelRules)
      }
    },
    // 返回新对象（避免数据污染
    getDefaultForm() {
      return {
        departments: [],
        positions: [],
        sequenceNo: 1,
        name: '',
        targetType: 1,
        ruleType: '',
        data: {
          ...ruleDefaultConfig.levelData,
          ...ruleDefaultConfig.checkData,
          ...ruleDefaultConfig.coefficientData
        },
        levelRules: [],
        kpiDeliveries: null,
        kpiErrors: null,
        kpiModifies: null,
        priceCalcType: null,
        formula: null,
        unitPrice: null,
        unit: null,
        typeId: 0
      }
    }
  },
  computed: {},
  created() {
    this.ruleTypeList = createRuleTypeList()
    this.formData = Object.assign({}, this.getDefaultForm())
  }
}
</script>

<style lang="stylus"></style>
<style scoped lang="less">
.scroll-view {
  height: 80vh;
  overflow-y: auto;
  padding: 24px 44px 0 48px;
}
/deep/ .ant-drawer-body {
  padding: unset !important;
}

/deep/ .eidtor-btn {
  margin: 24px 48px 10px 0;
}

.py-3 {
  font-weight: bold;
  color: #333;
}
/deep/ .allow-scroll {
  height: 80vh !important;
  overflow-y: auto !important;
  padding: 24px 44px 0px 48px !important;
}
</style>
