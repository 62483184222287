export const ruleDefaultConfig = {
    levelData: {
        lowLevel: {
            averageScore: 70,
            taskCountMonthly: 30,
            cooperationTime: 3,
            unitPrice: 60,
        },
        mediumLevel: {
            averageScore: 70,
            taskCountMonthly: 30,
            cooperationTime: 3,
            unitPrice: 80,
        },
        highLevel: {
            averageScore: 90,
            taskCountMonthly: 50,
            cooperationTime: 6,
            unitPrice: 100,
        },
    },

    checkData: {
        ahead: {
            score: 25,
        },
        onTime: {
            score: 20,
        },
        delay: {
            score: 0,
        },
        wrongTranslate: {
            maxScore: 25,
            minusScore: 5,
        },
        hardInjury: { maxScore: 20, minusScore: 4 },
        softInjury: { maxScore: 15, minusScore: 3 },
        lowFluency: { modifierCount: 30, score: 0 },
        mediumFluency: { modifierCount: 30, score: 10 },
        highFluency: { modifierCount: 10, score: 20 },
    },

    coefficientData: {
        productionCoefficient: {
            textCountRate: 25,
            highTextCount: { count: 160, coefficient: 1.2 },
            mediumTextCount: { count: 120, coefficient: 1 },
            lowTextCount: { count: 80, coefficient: 0.8 },
            dialogLengthRate: 25,
            highDialogLength: { count: 40, coefficient: 1.2 },
            mediumDialogLength: { count: 30, coefficient: 1 },
            lowDialogLength: { count: 20, coefficient: 0.8 },
            subjectRate: 25,
            hardSubject: 1.2,
            normalSubject: 1,
            easySubject: 0.8,
            platformRate: 25,
            hardPlatform: 1.2,
            normalPlatform: 1,
            easyPlatform: 0.8,
        },
        highWordCount: { count: 5000, coefficient: 1.5 },
        mediumWordCount: { count: 4000, coefficient: 1.2 },
        lowWordCount: { count: 3000, coefficient: 1 },
    }

}